<template>
  <b-modal
    visible
    scrollable
    centered
    title="Добавление пациента"
    hide-footer
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
  >
    <div class="patient-add-inner">
      <div class="d-flex justify-content-between">
        <div class="patient-input">
          <span>Фамилия <span class="required">*</span></span>
          <base-input
            v-model.trim="$v.patient.lastName.$model"
            :error="$v.patient.lastName.$error"
            :errors="errorsValidation.lastName"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
              marginTop: '5px'
            }"
            class="crm-form-field"
            placeholder="Введите фамилию"
          />
        </div>
        <div class="patient-input ml-2">
          <span>Имя <span class="required">*</span></span>
          <base-input
            v-model.trim="$v.patient.firstName.$model"
            :error="$v.patient.firstName.$error"
            :errors="errorsValidation.firstName"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
              marginTop: '5px'
            }"
            placeholder="Введите имя"
          />
        </div>
        <div class="patient-input ml-2">
          <span>Отчество</span>
          <base-input
            v-model.trim="$v.patient.middleName.$model"
            :error="$v.patient.middleName.$error"
            :errors="errorsValidation.middleName"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
              marginTop: '5px'
            }"
            placeholder="Введите отчество"
          />
        </div>
      </div>

      <div class="d-flex mt-4">
        <div class="patient-input">
          <span>Email</span>
          <base-input
            v-model="$v.patient.email.$model"
            :error="$v.patient.email.$error"
            :errors="errorsValidation.email"
            :input-style="{
              padding: '11px 15px 12px',
              backgroundColor: '#ffffff',
              marginTop: '5px'
            }"
            :mask="emailMask"
            class="patient-contacts"
          />
        </div>
        <div class="patient-input ml-2">
          <div
            class="crm-form-field mt-4"
          >
            <div class="crm-form-block">
              <div class="crm-input-value crm-input-value--checkbox">
                <base-checkbox
                  v-model="withUserIdPatient"
                  :disabled="!checkFeatureAccess({ name: 'Возможность выбрать С UserID', url: '/patients' })"
                  class="crm-checkbox"
                >
                  С UserID
                </base-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="withUserIdPatient"
          class="patient-input"
        >
          <span>
            Телефон <span class="required">*</span>
          </span>
          <b-input
            id="patient-tel"
            v-model="$v.patient.phoneNumber.$model"
            v-mask="phoneMaskString"
            placeholder="Введите телефон"
            title="Введите телефон"
            class="patient-contacts mt-1"
          />
          <div
            v-if="$v.patient.phoneNumber.$error"
            class="error-text mt-1"
          >
            {{ errorsValidation.phoneNumber[0] }}
          </div>
        </div>
      </div>

      <div class="clinics-form-fields mt-3">
        <div class="crm-form-field">
          <div class="crm-form-field">
            <div
              class="crm-form-block"
              :style="{
                minWidth: '200px',
                maxWidth: '240px'
              }"
            >
              <p class="mb-1">
                Дата рождения <span class="required">*</span>
              </p>
              <base-date-picker
                v-model="$v.patient.birthDate.$model"
                v-maska="'##.##.####'"
                :error="$v.patient.birthDate.$error"
                :max-date="maxDate"
                class="mt-2"
              />
            </div>
          </div>
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="mb-1">
                Пол <span class="required">*</span>
              </p>
              <div class="crm-radio-buttons crm-radio-buttons_horizontal">
                <base-radio-button
                  v-model="patient.sex"
                  class="crm-radio-button"
                  :name="true"
                >
                  Мужской
                </base-radio-button>

                <base-radio-button
                  v-model="patient.sex"
                  class="crm-radio-button"
                  :name="false"
                >
                  Женский
                </base-radio-button>

                <div
                  v-if="$v.patient.sex.$error"
                  class="validation-errors"
                >
                  <span
                    class="validation-error-text"
                  >
                    {{ errorsValidation.sex[0] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isPatientForIndemnity">
        <div class="clinics-form-fields">
          <div class="crm-form-field w-50">
            <div class="crm-form-block">
              <span>
                Страховая компания <span class="required">*</span>
              </span>
              <b-form-select
                ref="companyName"
                v-model="$v.policy.companyName.$model"
                :options="isInsuranceCompanies"
                value-field="title"
                text-field="title"
                class="mt-2"
                placeholder="Выберите страховую компанию"
                label="title"
                :state="validateState('companyName')"
                @input="changeCompany"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="crm-form-field w-50">
            <div class="crm-form-block">
              <span>
                Страховая программа <span class="required">*</span>
              </span>
              <b-form-select
                v-model="$v.policy.programName.$model"
                :options="filteredInsurancePrograms"
                value-field="name"
                text-field="name"
                class="mt-2"
                placeholder="Выберите страховую программу"
                label="name"
                :state="validateState('programName')"
                @input="changeProgram"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <span>
                Подпрограмма <span class="required">*</span>
              </span>
              <b-form-select
                v-model="$v.policy.subPrograms.$model"
                :options="filteredInsuranceSubprograms"
                value-field="name"
                text-field="name"
                class="mt-2"
                placeholder="Выберите страховую программу"
                label="name"
                :state="validateState('subPrograms')"
                @input="onSubprogramChange($event)"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="crm-form-field">
            <div class="crm-form-block">
              <b-form-group>
                <span>
                  Номер полиса <span class="required">*</span>
                </span>
                <b-form-input
                  v-model="$v.policy.policyId.$model"
                  type="text"
                  class="crm-form-field mt-2"
                  :state="validateState('policyId')"
                  trim
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >
                  Данное поле обязательно*
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="w-25">
              <div
                class="crm-form-block"
                :style="{
                  minWidth: '200px',
                }"
              >
                <span>
                  Дата начала <span class="required">*</span>
                </span>
                <base-date-picker
                  v-model="policy.startDate"
                  v-maska="'##.##.####'"
                  :error="$v.policy.startDate.$error"
                  :max-date="maxPolicyEditData"
                  fluid
                  class="crm-datepicker"
                />
              </div>
            </div>
            <div class="w-25 ml-5">
              <div
                class="crm-form-block"
                :style="{
                  minWidth: '200px',
                }"
              >
                <span>
                  Дата окончания <span class="required">*</span>
                </span>
                <base-date-picker
                  v-model="policy.endDate"
                  v-maska="'##.##.####'"
                  :error="$v.policy.endDate.$error"
                  :min-date="minPolicyEditDateTo"
                  fluid
                  class="crm-datepicker"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="crm-wrapper-buttons">
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          :loading="isSaving"
          class="px-3 mt-3"
          @click="onClickSave"
        >
          Сохранить
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import { formatISO } from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { dateWithoutTime } from '@/helpers/utils';
import { showErrorCustomMessage, showValidationErrorMessage } from '@/helpers/messages';
import emailMask from 'text-mask-addons/dist/emailMask';
import { CREATE_PATIENT_MODE } from '@/helpers/consts';

import {
  BaseInput,
  BaseCheckbox,
  BaseRadioButton,
  BaseDatePicker,
} from '@/components/base';

const DEFAULT_COMPANY = 'СОГАЗ';
const DEFAULT_PROGRAMM = 'Разовые услуги';
const DEFAULT_SUBPROGRAMM = 'АПО';

export default {
  name: 'PatientAddModal',
  components: {
    BaseInput,
    BaseCheckbox,
    BaseRadioButton,
    BaseDatePicker,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    mode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: true,
      isSaving: false,
      withUserIdPatient: true,
      emailMask,
      checkValidations: false,
      maxDate: new Date(),
      insuranceCompanies: [],
      insurancePrograms: [],
      insuranceSubprograms: [],
      patient: {
        lastName: null,
        firstName: null,
        middleName: null,
        birthDate: null,
        sex: null,
        phoneNumber: null,
        email: null,
      },
      policy: {
        companyName: null,
        programName: null,
        subPrograms: null,
        policyId: null,
        startDate: null,
        endDate: null,
      },
    };
  },
  validations() {
    return {
      patient: {
        lastName: {
          required,
          isValid: (lastName) => !/[`~!@#$%^&*()0-9]/.test(lastName),
        },
        firstName: {
          required,
          isValid: (firstName) => !/[`~!@#$%^&*()0-9]/.test(firstName),
        },
        middleName: {
          isValid: (middleName) => !/[`~!@#$%^&*()0-9]/.test(middleName),
        },
        birthDate: { required },
        sex: { required },
        phoneNumber: {
          required: this.withUserIdPatient ? required : () => true,
          isValid: (phone) => {
            if (!this.withUserIdPatient || !phone) return true;
            const rawPhone = phone.replace(/[^\d+]/g, '');
            if (rawPhone.startsWith('+7') && rawPhone.length === 12) return true;
            if (rawPhone.startsWith('+55') && (rawPhone.length === 13 || rawPhone.length === 14)) return true;
            return false;
          },
        },
        email: {},
      },
      policy: {
        companyName: { required: this.checkPolicyField },
        subPrograms: { required: this.checkPolicyField },
        programName: { required: this.checkPolicyField },
        policyId: { required: this.checkPolicyField },
        startDate: { required: this.checkPolicyField },
        endDate: { required: this.checkPolicyField },
      },
    };
  },
  computed: {
    phoneMaskString() {
      if (!this.patient.phoneNumber || this.patient.phoneNumber === '+') return '+#';
      if (this.patient.phoneNumber.match(/^\+?5/)) {
        if (this.patient.phoneNumber.length === 19) return '+55 (##) #####-####';
        return '+55 (##) ####-####?#';
      }
      return '+7 (###) ###-##-##';
    },
    errorsValidation() {
      const errors = {};

      errors.lastName = [];
      if (!this.$v.patient.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }
      if (!this.$v.patient.lastName.isValid) {
        errors.lastName.push('Некорректное значение поля');
      }
      errors.firstName = [];
      if (!this.$v.patient.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }
      if (!this.$v.patient.firstName.isValid) {
        errors.firstName.push('Некорректное значение поля');
      }
      errors.middleName = [];
      if (!this.$v.patient.middleName.isValid) {
        errors.middleName.push('Некорректное значение поля');
      }
      errors.phoneNumber = [];
      if (!this.$v.patient.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }
      if (!this.$v.patient.phoneNumber.isValid) {
        errors.phoneNumber.push('Некорректное значение телефона');
      }
      errors.email = [];
      if (!this.$v.patient.email.required) {
        errors.email.push('Поле не может быть пустым');
      }
      errors.birthDate = [];
      if (!this.$v.patient.birthDate.required) {
        errors.birthDate.push('Поле не может быть пустым');
      }
      errors.sex = [];
      if (!this.$v.patient.sex.required) {
        errors.sex.push('Выберите один из вариантов');
      }
      errors.startDate = [];
      if (!this.$v.policy.startDate.required) {
        errors.startDate.push('Некорректное значение');
      }
      errors.endDate = [];
      if (!this.$v.policy.endDate.required) {
        errors.endDate.push('Некорректное значение');
      }
      return errors;
    },
    checkPolicyField() {
      return this.isPatientForIndemnity ? required : () => true;
    },
    isPatientForDoctis() {
      return this.mode === CREATE_PATIENT_MODE.ForDoctis;
    },
    isPatientForIndemnity() {
      return this.mode === CREATE_PATIENT_MODE.ForIndemnity;
    },
    isInsuranceCompanies() {
      const companies = this.insuranceCompanies.map(({ company }) => (company));
      return companies.filter((item) => !item.isHidden);
    },
    filteredInsurancePrograms() {
      return this.insurancePrograms.filter((item) => !item.isHidden);
    },
    filteredInsuranceSubprograms() {
      return this.insuranceSubprograms?.filter((item) => !item.isHidden);
    },
    maxPolicyEditData() {
      return this.policy.endDate || new Date();
    },
    minPolicyEditDateTo() {
      return this.policy.startDate || null;
    },
  },
  watch: {
    withUserIdPatient() {
      this.patient.phoneNumber = null;
    },
  },
  async created() {
    this.insuranceCompanies = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    if (this.isPatientForIndemnity) {
      this.setDefaultValueInPolicyField();
    }
  },
  methods: {
    setDefaultValueInPolicyField() {
      const defaultCompany = this.insuranceCompanies.find(({ company: { title } }) => title.includes(DEFAULT_COMPANY));
      const defaultProgram = defaultCompany.programsWithSubprograms.find(({ program: { name } }) => name.includes(DEFAULT_PROGRAMM));
      const defaultSubPrograms = defaultProgram.subPrograms.find(({ name }) => name.includes(DEFAULT_SUBPROGRAMM));

      this.policy.companyName = defaultCompany.company.title;
      this.policy.programName = defaultProgram.program.name;
      this.policy.subPrograms = defaultSubPrograms.name;
    },
    setInsuranceEntities() {
      try {
        if (!this.policy.companyName) return;
        const companyObject = this.insuranceCompanies
          .find(({ company: { title } }) => title === this.policy.companyName);
        const programs = companyObject.programsWithSubprograms.map(({ program }) => (program));
        this.insurancePrograms = programs;

        if (!this.policy.programName) return;
        const programObject = companyObject.programsWithSubprograms.find(({ program: { name } }) => name === this.policy.programName);
        this.insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name, isHidden }) => ({ id: subprogramId, name, isHidden }));
      } catch (e) {
        console.error(e);
      }
    },
    changeCompany() {
      this.policy.programName = null;
      this.policy.subPrograms = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeProgram() {
      this.policy.subPrograms = null;
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    onSubprogramChange(subprogramId) {
      if (!subprogramId) return;

      const subprogram = this.insuranceSubprograms.find((subprog) => subprog.name.includes(subprogramId));
      this.policy.subProgramV2Id = subprogram.id;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.policy[name];
      return $dirty ? !$error : null;
    },
    onClose() {
      this.$emit('input', false);
      // this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickSave() {
      this.$v.$touch();
      this.checkValidations = true;
      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }
      try {
        const dateWithTime = new Date(this.policy.endDate);
        dateWithTime.setHours(23);
        dateWithTime.setMinutes(59);

        const formattedPatientData = {
          ...this.patient,
          mode: this.mode,
          birthDate: dateWithoutTime(formatISO(this.patient.birthDate)),
          policyId: this.policy.policyId?.trim(),
          startDate: this.policy.startDate ? formatISO(this.policy.startDate) : null,
          endDate: this.policy.endDate ? formatISO(dateWithTime) : null,
          subProgramV2Id: this.policy.subProgramV2Id,
        };

        this.isSaving = true;
        await this.$store.dispatch(this.$types.PATIENT_CREATE_WITH_MODE, formattedPatientData);

        this.$emit('success');
        this.onClose();
      } catch (error) {
        showErrorCustomMessage(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-add {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1px;
  height: calc(100vh - 170px);
}
.clinics-form-fields {
  margin-bottom: 15px !important;
}

.patient-add-inner {
  padding: 0 20px 0 0;
}

.patient-input{
  display: flex;
  flex-direction: column;
  width: 33%;
}

.patient-contacts{
  height: 41px;
  max-width: 243px;
}
.crm-form-block {
  width: 100%;
  display: block;
}
.crm-radio-buttons {
  position: relative;
  display: flex;
  height: 40px;
  align-items: center;

  &.crm-radio-buttons_horizontal .crm-radio-button {
    &:not(:first-child) {
      margin-left: 35px;
    }
  }
}
.error-text {
  font-size: 12px;
  color: $cell-dangerous;
}
::v-deep.crm-icon-cross {
  margin-right: 15px;

  path {
    fill: $blue;
  }
}
.button_transparent {
  width: unset;
}
.add-policy-button {
  margin-bottom: 30px;
}

::v-deep.crm-input-value--checkbox {
  background: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  height: 50px;

  .custom-checkbox {
    display: inline-block;
    margin: auto 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    & + .custom-checkbox {
      margin-left: 20px;
    }
  }
}
.validation-errors{
  position: absolute;
  top: 30px;
  z-index: 1;
}
</style>
